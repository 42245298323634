const firebaseConfig = {
  apiKey: "AIzaSyDBBFREryS7JyVVECBXv8LCwBapgeqRFeY",
  authDomain: "jesieniara-ecommerce.firebaseapp.com",
  projectId: "jesieniara-ecommerce",
  storageBucket: "jesieniara-ecommerce.appspot.com",
  messagingSenderId: "880854490184",
  appId: "1:880854490184:web:520a493f02f10b1db98c7d",
};

export default firebaseConfig;
